import React from 'react';
import {Form, Col, Row, Button, Badge} from 'react-bootstrap'

const AddOrderToGroupDropDown = ({measurements, orderGroupings, handleOrderGroupingsChange}) => {

  return (
    <Row>
        <Col>
          <Form.Group as={Row} >
            <Form.Label column sm={3}>Add to Group</Form.Label>
            <Col>
              <Form.Control 
                as="select" 
                value={measurements['groupingId'] ? measurements['groupingId'] : ''} 
                onChange={handleOrderGroupingsChange} 
                // disabled={isDisabled}
              >
                <option value=''>--Select Group--</option>
                {
                  orderGroupings && Array.isArray(orderGroupings) ? orderGroupings.map(x => {
                    return (
                      <option key={x.id} value={x.id}>{x.groupName}</option>
                    )
                  }) : <></>
                }
              </Form.Control>
            </Col>
          </Form.Group>
      </Col>
    </Row>
  )
}

export default AddOrderToGroupDropDown