const getTotalDesignCost = (item) => {
  let totalDesignCost = 0;
  if (item && item.design) {
    const designObj = JSON.parse(item.design);
    if (Object.keys(designObj).length > 0) {
      Object.keys(designObj).forEach(option => {
        totalDesignCost += designObj[option].optionPrice ? Number(designObj[option].optionPrice) : 0;
      })
    }
  }
  return totalDesignCost.toFixed(2);
}

export default getTotalDesignCost