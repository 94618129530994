import React, { useState } from 'react';
import {Table, Form, Badge, Button} from 'react-bootstrap'
import constants from '../helpers/constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactDatePickerFormat, addDays } from '../helpers/dateTime';
import { isProcessStarted } from '../helpers/statusHelpers'
import { isAdmin } from '../helpers/roles';
import isElectronicPayment from '../helpers/electronicPayment';
import getTotalDesignCost from '../helpers/getTotalDesignCost';
import styles from './salesItems.module.css';

const SalesItems = ({salesItems, measurements, totalAmount, handleAddLine, handleSetTypeChange, handleItemTypeChange, handleFabricChange, handleAlterationChange, handlePriceChange, handleUrgentDateChange, handleOnDeleteRowClick, handleOnRefundRowClick, errMsg, isDisabled, inSummary, showFailAlert, orderHasDisbursedRPoints, isReferralActive, orderTimeAfterReferralFeature}) => {
  const [itemTypes, setitemTypes] = useState(constants.ITEM_TYPES);

  const getMinDate = (lineItem) => {
    const noOfDays = constants.MIN_BUFFER_DAYS[lineItem.item_type];
    return addDays(new Date(), noOfDays);
  }

  const isRefundStatusApprovedOrPending = (lineItem) => {
    if (lineItem && lineItem.refund_status) {
      const status = lineItem.refund_status;
      if (status === constants.REFUND_STATUS.APPROVED || status === constants.REFUND_STATUS.PENDING) {
        return true;
      }
    }
    return false;
  }

  const underOneMonthSinceCollected = (item) => {
    if (item.status === constants.STATUS_COLLECTED && measurements.fullyPaid) {
      const thirtyDaysFromBalancePaid = addDays(measurements.balancePaid, 30);
      const today = new Date();
      
      if (today < thirtyDaysFromBalancePaid) {
        return true;
      }
      return false;
    }
    return true;
  }

  return (
    <>
      {!inSummary &&  
        <div className="text-right mb-3">
          <Button 
            size="sm"
            className="shadow"
            variant="primary" 
            onClick={handleAddLine} 
            className="mr-1"
            disabled={isDisabled}
            // disabled={showSuccessAlert ? 'disabled' : false}
          >
            <span className="oi oi-plus" disabled={isDisabled}></span> Add Line
          </Button>
        </div>
      }
      <Table>
        <thead>
          <tr>
            <th>Set</th>
            <th>Item Type</th>
            <th>Fabric Type/ Description</th>
            <th className="text-center">Alteration</th>
            {inSummary && 
              <>
                <th>Price</th>
                <th className="text-right">Design</th>
                <th className="text-right">Price + Design</th>
                <th className="text-right">R Points Used <br />(${Number(totalAmount.bonusAmountUsed ? totalAmount.bonusAmountUsed : 0).toFixed(2)})</th>
                {/* <th className="text-right">Price After Bonus</th> */}
                <th className="text-right">Discount<br />({totalAmount.discountAmount}{totalAmount.discountType === 'Percentage' ? '%' : '$'})</th>
                <th className="text-right">Price per Item</th>
              </>
            }
            <th>Urgent Date</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
        { 
            salesItems && (
              salesItems.map((lineItem, index) => {
                return (
                    <tr key={index} className={lineItem.refund_status === constants.REFUND_STATUS.APPROVED ? styles.refundedRow : ''}>
                        <td style={{width:'7%'}}>
                          {
                            ['ACCESSORY', 'BESPOKE', 'OTHERS'].findIndex(item => item === lineItem.item_type) === -1 ? (
                              <Form.Control
                                  required
                                  data-property={'setType'}
                                  as="select"
                                  value={lineItem['setType']}
                                  data-index={index} 
                                  onChange={handleSetTypeChange} 
                                  disabled={isProcessStarted(lineItem) || isRefundStatusApprovedOrPending(lineItem)}
                                  >
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                </Form.Control>
                            ) :<></>
                          }
                        </td>
                      <td>
                        <Form.Control
                          required
                          // data-property={'countryName'}
                          // data-country-id={item.countryId} 
                          as="select"
                          value={lineItem['item_type']}
                          data-index={index} 
                          onChange={handleItemTypeChange} 
                          disabled={inSummary || isDisabled || lineItem.status || isRefundStatusApprovedOrPending(lineItem)}
                          >
                            <option value="">-- Select --</option>
                            {
                              itemTypes && Array.isArray(itemTypes) ?
                              itemTypes.map((item, index2) => {
                                return (
                                  <option key={index2}>{item}</option> 
                                )
                              })
                              :<></>
                            }
                            <option value="ACCESSORY">ACCESSORY</option>
                            <option value="BESPOKE">BESPOKE</option>
                            <option value="OTHERS">OTHERS</option>
                        </Form.Control>
                        {/* {errMsg && errMsg.itemType &&
                          <div className="text-danger">what?</div>
                        } */}
                      </td>
                      <td>
                        <Form.Control 
                          required
                          // data-property={}
                          type="text" 
                          placeholder="Please enter value" 
                          value={lineItem['item_desc']} 
                          data-index={index} 
                          onChange={handleFabricChange} 
                          disabled={inSummary || isDisabled || lineItem.status || isRefundStatusApprovedOrPending(lineItem)}
                          />
                      </td>
                      <td className="text-center">
                        <Form.Check 
                          required
                          // data-property={}
                          type="checkbox" 
                          placeholder="Please enter value" 
                          checked={lineItem['alteration']} 
                          data-index={index} 
                          onChange={handleAlterationChange} 
                          disabled={inSummary || isDisabled || lineItem.status || isRefundStatusApprovedOrPending(lineItem)}
                        />
                      </td>
                      {inSummary && 
                        <>
                          <td>
                            <Form.Control 
                              className="text-right"
                              required
                              // data-property={}
                              type="number" 
                              placeholder="Please enter value" 
                              value={lineItem['price']} 
                              data-index={index} 
                              onChange={handlePriceChange} 
                              disabled={isDisabled || lineItem.status || isRefundStatusApprovedOrPending(lineItem)}
                              />
                          </td>
                          <td className="text-right">${getTotalDesignCost(lineItem)}</td>
                          <td className="text-right">${(Number(lineItem['price']) + Number(getTotalDesignCost(lineItem))).toFixed(2)}</td>
                          <td className="text-right text-muted">(${Number(lineItem.bonusOffset ? lineItem.bonusOffset : 0).toFixed(2)})</td>
                          {/* <td className="text-right">${Number(lineItem.priceAfterBonusOffset).toFixed(2)}</td> */}
                          <td className="text-right text-muted">(${Number(lineItem.discount ? lineItem.discount : 0).toFixed(2)})</td>
                          <td className="text-right">${Number(lineItem.priceAfterBonusOffset ? lineItem.priceAfterBonusOffset : lineItem.priceAfterDiscount).toFixed(2)}</td>
                        </>
                      }
                      <td>
                        <DatePicker
                          className="reactDatePicker"
                          dateFormat="dd/MM/yyyy"
                          todayButton="Today"
                          selected={lineItem.urgent_date ? ReactDatePickerFormat(lineItem.urgent_date) : null}
                          minDate={getMinDate(lineItem)}
                          // maxDate={addDays(itemInByObject.original, fwdBuffer)}
                          // placeholderText="Select New Item In By Date"
                          onChange={(date) => handleUrgentDateChange(date, index)}
                          withPortal
                          disabled={isDisabled || isRefundStatusApprovedOrPending(lineItem) || inSummary}
                          
                        />
                      </td>
                      <td className="text-center d-flex align-items-start">
                        {!lineItem.status  && !isRefundStatusApprovedOrPending(lineItem) && !isDisabled &&
                          <span 
                            className="oi oi-circle-x pointer flex-fill"
                            title="Delete row"
                            data-index={index}
                            onClick={handleOnDeleteRowClick}
                          ></span>
                        }

                        {isRefundStatusApprovedOrPending(lineItem) ? 
                          (
                            <> 
                              {lineItem.refund_status === constants.REFUND_STATUS.PENDING &&  (
                                <Badge pill variant="warning">Refund: {lineItem.refund_status}</Badge>
                              )}
                              {lineItem.refund_status === constants.REFUND_STATUS.APPROVED &&  (
                                <Badge pill variant="info">Refund: {lineItem.refund_status}</Badge>
                              )}
                            </>
                          ) : 
                          ( 
                            <>
                              { isAdmin() && 
                              lineItem.item_type !== 'OTHERS' && 
                              Number(totalAmount.balance) === 0 && 
                              underOneMonthSinceCollected(lineItem) &&
                                <span 
                                  className="oi oi-action-undo pointer flex-fill"
                                  title="Refund item"
                                  data-index={index}
                                  onClick={handleOnRefundRowClick}
                                ></span>
                              }
                            </>
                          ) 
                        }
                      </td>
                    </tr>
                )
              })
            )
          }
          {inSummary && 
            <>
              { orderTimeAfterReferralFeature
                && !orderHasDisbursedRPoints 
                && totalAmount.bonusBalance > 0 ? (
                <tr style={{borderTop:'3px solid #0f0f0f'}}>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="text-right"><strong>R Points</strong><br /><span className="text-muted"><small>You have {Number(totalAmount.bonusBalance - totalAmount.bonusAmountUsed).toFixed(2)} R Points left</small></span>
                  </td>
                  <td className="text-right" >
                    <Form.Control 
                      className="text-right"
                      required
                      data-property={"bonusAmountUsed"}
                      type="text" 
                      placeholder="Enter R Points" 
                      value={totalAmount['bonusAmountUsed']} 
                      // data-index={index} 
                      onChange={handlePriceChange} 
                      disabled={isDisabled}
                    />
                    {/* <strong>${totalAmount.balance}</strong> */}
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              ) : <tr style={{borderTop:'3px solid #0f0f0f'}}></tr> }
              {!orderHasDisbursedRPoints &&
                <tr style={{borderTop: totalAmount.bonusBalance <= 0 ? '3px solid #0f0f0f' : ''}}>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  {!isReferralActive ? 
                    <>
                      <td>
                        Discount Type<br />
                        <Form.Control 
                          required
                          data-property={'discountType'}
                          // data-country-id={item.countryId} 
                          as="select"
                          // data-index={index} 
                          value={totalAmount['discountType'] ? totalAmount['discountType'] : ''} 
                          onChange={handlePriceChange}
                          disabled={isDisabled} 
                          >
                            <option value=''>Nil</option>
                            <option value='Cash'>Cash</option>
                            <option value='Percentage'>Percentage</option>
                        </Form.Control>
                      </td>
                      <td className="text-right">Disc. Amt<br />
                        <Form.Control 
                          className="text-right"
                          required
                          data-property={"discountAmt"}
                          type="text" 
                          placeholder="Enter discount" 
                          value={totalAmount['discountAmount']} 
                          // data-index={index} 
                          onChange={handlePriceChange} 
                          disabled={isDisabled}
                        />
                      </td>
                    </>
                    : <><td></td><td></td></>
                  }
                  <td className="text-right">
                  </td>
                  <td>&nbsp;</td>
                  <td className="text-right"><strong>Net Total</strong></td>
                  <td className="text-right"><strong>${Number(totalAmount.netTotal).toFixed(2)}</strong></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              }
              <tr>
                <td></td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td colSpan="2">
                  Payment Method<br />
                  <Form.Control 
                    required
                    data-property={'paymentMethod'}
                    // data-country-id={item.countryId} 
                    as="select"
                    value={totalAmount.paymentMethod}
                    onChange={handlePriceChange} 
                    disabled={isDisabled}
                    >
                      <option value=''>-- Select Payment Method --</option>
                      <option>CASH</option>
                      <option>NETS</option>
                      <option>CREDIT CARD</option>
                      <option>PAY NOW</option>
                      <option>STRIPE</option>
                      <option>OTHERS</option>
                  </Form.Control>
                </td>
                {isElectronicPayment(totalAmount) ? 
                  <>
                    <td className="text-right"><strong>Electronic Trx ID</strong></td>
                    <td className="text-right">
                      <Form.Control 
                        className="text-right"
                        required
                        data-property={"electronicTrxId"}
                        type="text" 
                        placeholder="Enter Trx Id" 
                        value={measurements['electronicTrxId']} 
                        // data-index={index} 
                        onChange={handlePriceChange} 
                        disabled={isDisabled}
                      />
                    </td>
                  </>
                  : 
                  <>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </>
                }
                <td className="text-right"><strong>Deposit/ Payment</strong></td>
                <td className="text-right">
                  <Form.Control 
                    className="text-right"
                    required
                    data-property={"deposit"}
                    type="text" 
                    placeholder="Enter Deposit/Payment" 
                    value={totalAmount['deposit']} 
                    // data-index={index} 
                    onChange={handlePriceChange} 
                    disabled={isDisabled}
                  />
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className="text-right"><strong>Balance</strong><br /><span className="text-muted"><small>Balance must be zero to complete</small></span></td>
                <td className="text-right" style={{'paddingRight': '1.5rem'}}><strong>${totalAmount.balance}</strong></td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>

              </tr>
            </>
          
          }
          { totalAmount.refundedAmt && totalAmount.refundedAmt !== 0 ? (
            <tr>
              <td className="text-right">Refunded Amount<br /></td>
              <td className="text-right" style={{'paddingRight': '1.5rem'}}>${Number(totalAmount.refundedAmt).toFixed(2)}</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              {inSummary && 
                <>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </>
              }
            </tr>
            ) : <></>
          }
        </tbody>
        <tfoot>
          {/* <tr>
            <td className="text-right text-bold">Total</td>
            <td className="text-bold">${getTotal()}</td>
            <td></td>
          </tr> */}

        </tfoot>
      </Table>
      {/* {(<div><pre>{JSON.stringify(totalAmount, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(orderTimeAfterReferralFeature, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(orderHasDisbursedRPoints, null, 2) }</pre></div>)} */}
    </>
  )
}

export default SalesItems