import React from 'react';
import { 
  DDMMYYYYFormat, 
} from '../../helpers/dateTime';
import constants from '../../helpers/constants';
import styles from './receipt.module.css';

const Receipt = ({
  custDetails,
  measurements,
  salesItems,
  totalAmount,
}) => {

  let discountAmount = Number(totalAmount.discountAmount);
  if (totalAmount.discountType === 'Percentage') {
    discountAmount = (Number(totalAmount.discountAmount)/100) * Number(totalAmount.subTotal)
  }  
  
  const itemTypeField = (item) => {
    let statusDesc = '';
    switch (item.refund_status) {
      case constants.REFUND_STATUS.PENDING:
        statusDesc = 'Pending Refund';
        break;
      case constants.REFUND_STATUS.APPROVED:
        statusDesc = 'Refunded';
        break;
      default:
        break;
    } 

    return (
      <>
        {
          item.refund_status === constants.REFUND_STATUS.PENDING || item.refund_status === constants.REFUND_STATUS.APPROVED ? 
          (
            <>{item.item_type}<span className="text-muted">&nbsp;<small>({statusDesc})</small></span></>
          ) : 
          <>{item.item_type}</>
        }
      </>
    )
  }

  const priceField = (item) => {
    let statusDesc = '';
    switch (item.refund_status) {
      case constants.REFUND_STATUS.PENDING:
        statusDesc = 'Pending Refund';
        break;
      case constants.REFUND_STATUS.APPROVED:
        statusDesc = 'Refunded';
        break;
      default:
        break;
    } 

    return (
      <>
        {
          item.refund_status === constants.REFUND_STATUS.PENDING || item.refund_status === constants.REFUND_STATUS.APPROVED ? 
          (
            <>(${Number(item.priceAfterDiscount).toFixed(2)})</>
          ) : 
          (`$${Number(item.priceAfterDiscount).toFixed(2)}`)
        }
      </>
    )
  }
  

  return (
    <>
      <div id="invoice-wrapper">
        <div className={styles.invoiceBox}>
          <style>
            {`
            @media print {
              #invoice-wrapper {
                display:none;
              }
              input {
                  display:none;
              }
            }
            `}
          </style>
          <table cellPadding="0" cellSpacing="0" style={{width:"100%"}}>
            <tbody>
              <tr className="top">
                  <td colSpan="5">
                      <table style={{width:"100%"}}>
                        <tbody>
                          <tr>
                              <td className="title pb-4" style={{textAlign:"center"}}>
                                  <img src="https://reddotbespoke.com/wp-content/uploads/2016/02/logo-white-background.png" style={{width:"100%", maxWidth:"300px"}}/>
                              </td>                                           
                          </tr>
                        </tbody>
                      </table>
                  </td>
              </tr>
              <tr className="information">
                <td colSpan="5">
                    <table style={{width:"100%"}}>
                      <tbody>
                        <tr>
                            <td>
                                Customer Details <br /> 
                                Name: {custDetails.name} <br /> 
                                Email: {custDetails.email} <br /> 
                                Address: {custDetails.address} <br /> 
                                Phone: {custDetails.phone}
                            </td>
                            <td colSpan="2">
                              Consultant Name: {measurements.consultant_name}<br /> 
                              Cust ID: {custDetails.id} <br /> 
                              Date: {DDMMYYYYFormat(new Date())} <br /> 
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr>
                <td>
                    <table className={styles.item_table} style={{border:"1px solid #eee", width:"100%", borderCollapse: "collapse !important"}}>
                      <thead>
                        <tr className={styles.heading}>
                            <th colSpan="" style={{padding:"5px", fontWeight: "bold", textAlign: "left"}}>
                                Item Type
                            </th>
                            <th colSpan="" style={{padding:"5px", fontWeight: "bold", textAlign: "left"}}>
                                Fabric Type
                            </th>
                            <th colSpan="" style={{padding:"5px", fontWeight: "bold", textAlign: "left"}}>
                                Urgent Date
                            </th>
                            <th colSpan="" style={{paddingRight:"15px", fontWeight: "bold", textAlign: "right"}}>
                                Net Price
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          salesItems && Array.isArray(salesItems) && salesItems.map(item => {
                            return (
                              <tr>
                                <td style={{padding: "5px"}}>{itemTypeField(item)}</td>
                                <td style={{padding: "5px", textAlign:"left"}}>{item.item_desc}</td>
                                <td style={{padding: "5px", textAlign:"left"}}>{DDMMYYYYFormat(item.urgent_date)}</td>
                                <td className="text-right">{priceField(item)}</td>
                              </tr>
                            )
                          })
                        }
                        {/* <tr className="total">
                            <td colSpan="2" style={{padding: "5px"}}></td>
                            <td style={{padding: "5px"}} className="text-right">
                                SubTotal:
                            </td>
                            <td style={{padding: "5px", fontWeight: "bold"}} className="text-right">
                                ${Number(totalAmount.subTotal).toFixed(2)}
                            </td>
                        </tr> */}
                          <tr className="">
                          {
                           Number(totalAmount.discountAmount) > 0 ?
                            (
                              <>
                              <td style={{padding: "5px"}} className="text-left">
                                  Discount:
                              </td>
                              <td style={{padding: "5px"}} className="text-left">
                                ${discountAmount.toFixed(2)}
                              </td>
                              </>
                            ) : <><td>&nbsp;</td><td>&nbsp;</td></>
                          }
                            <td style={{padding: "5px"}} className="text-right">
                              Net Total:
                            </td>
                            <td style={{fontWeight: "bold"}} className="text-right">
                                ${Number(totalAmount.netTotal).toFixed(2) }
                            </td>
                          </tr>
                        {/* <tr className="total">
                            <td colSpan="2" style={{padding: "5px"}}></td>
                            <td style={{padding: "5px"}} className="text-right">
                              Nett Total:
                            </td>
                            <td style={{padding: "5px", fontWeight: "bold"}} className="text-right">
                                ${Number(totalAmount.netTotal).toFixed(2) }
                            </td>
                        </tr> */}
                        <tr className="">
                          <td style={{padding: "5px"}}>
                              Payment Method:
                          </td>
                          <td style={{padding: "5px", fontWeight: "bold", textAlign: "left"}}>
                              {totalAmount.paymentMethod}
                          </td>
                          <td style={{padding: "5px"}} className="text-right">
                            Deposit:
                          </td>
                          <td style={{fontWeight: "bold"}} className="text-right">
                              (${Number(totalAmount.deposit).toFixed(2) })
                          </td>
                        </tr>
                        <tr className="">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{padding: "5px"}} className="text-right">
                              Balance:
                            </td>
                            <td style={{fontWeight: "bold"}} className="text-right">
                                ${Number(totalAmount.balance).toFixed(2) }
                            </td>
                        </tr>
                        {totalAmount.refundedAmt && totalAmount.refundedAmt !== 0 ?
                          <tr className="">
                            <td style={{padding: "5px"}} className="text-left">
                              Refunded:
                            </td>
                            <td className="text-left">
                                ${Number(totalAmount.refundedAmt * -1).toFixed(2) }
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr> : <></>
                        }
                      </tbody>
                    </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{textAlign:"center"}}>Questions? Check out our <a target="_blank" href="http://reddotbespoke.com/faq/FAQ.pdf">Faq</a> or give us a call at +65 6612 4935.</p>
          <p style={{textAlign:"center"}}>This email was sent from a notification-only address. Please do not reply.</p>
        </div>
        <div>
            <p style={{textAlign:"center", color:"#4d4d4d", fontSize:"12px", padding:"10px", lineHeight:"1.42857"}}>
              <a target="_blank" href="http://reddotbespoke.com/terms-of-use/terms-of-use.pdf">Terms of use</a> | 
              <a target="_blank" href="http://reddotbespoke.com/privacy-policy/privacy-policy.pdf">Privacy Policy</a>|
              <a target="_blank" href="http://reddotbespoke.com/pdpa/pdpa.pdf"> PDPA Consent</a>
              <br />
              Red Dot Bespoke , 672 North Bridge Road #02-00 (S)188803<br />UEN: 201510121K
            </p>
        </div>
      </div>
      {/* {(<div><pre>{JSON.stringify(custDetails, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(measurements, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(salesItems, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(totalAmount, null, 2) }</pre></div>)} */}
    </>
  )
}

export default Receipt  