import Fetch from "./fetch";
import constants from './constants';


const isOrderTimeAfterReferralFeature = async (orderTime) => {
  let referralStartDate = null;
  let result = true;
  const url=`${constants.BACKEND_URL}/settings/referralStartDate`
  const res = await Fetch.get(url)

  if (res && res.data && res.data.value) {
    referralStartDate = new Date(res.data.value)
    if (new Date(orderTime) < referralStartDate) result = false;
    return result;
  }
}

export default isOrderTimeAfterReferralFeature