import React from 'react';
import {Form, Col, Row, Alert, Button, Badge} from 'react-bootstrap'
import AddOrderToGroupDropDown from './addOrderToGroupDropDown'
import { replacerRegex, replacer, upperCaserRegex, upperCaser  } from '../helpers/string'
import { isAdmin, isManager } from '../helpers/roles'

const SalesSummary = ({
  measurements,
  salesItems,
  consultants,
  orderGroupings,
  selectedConsultant,
  handleOnSaveOrderClick,
  handleOnConsultantChange,
  handleOnPrintReceiptClick,
  handleOnSendReceiptClick,
  handleOnCompleteOrderClick,
  handleOnDownloadAllOrdersClick,
  isDisabled,
  hasItemTypeChanged,
  handleOrderGroupingsChange,
  ...props
}) => {
  const  allItemTypesAreOthers = (params) => {
    let allItemTypesAreOthers = true;
    if (salesItems.length === 0) allItemTypesAreOthers = false;

    if (salesItems && Array.isArray(salesItems)) {
      salesItems.forEach((item) => {
        if (item.item_type.toUpperCase() !== 'OTHERS' && item.item_type.toUpperCase() !== 'ACCESSORY') allItemTypesAreOthers = false;
      })
    }
    return allItemTypesAreOthers
  }

  const allDesignFormReady = () => {
    let ready = true;
    for (let i = 0; i < salesItems.length; i++) {
      const element = salesItems[i];
      if (!element.designSheetDownloadable) {
        ready = false;
      }
    }
    return ready
  }

  const bodyMeasurements = (bodyPart) => {
    const bodyPartHaveMeasurementFilled = Object.entries(measurements[bodyPart]).some(([key, value]) => {
      return value !== "";
    }) 

    return bodyPartHaveMeasurementFilled ? 
      (
        <>
          
          {bodyBadges(bodyPart)}
        </>
      )
      : `Please Enter some measurements for ${bodyPart.replace(replacerRegex, replacer).replace(upperCaserRegex, upperCaser)}`
  }

  const bodyBadges = (bodyPart) => {
    return Object.entries(measurements[bodyPart]).map(([key, value], index) => {
      return value && value.inch ? (
          <Badge key={index} pill variant="primary" className="mr-1">
            {key.replace(replacerRegex, replacer).replace(upperCaserRegex, upperCaser)}: {value.inch}
          </Badge>
        ) : ''
      })
  }
  
  
  return (
    <>
      
      { (isAdmin() || isManager()) &&
      <Row>
        <Col>
          <Form.Group as={Row} >
            <Form.Label column sm={3}>Consultant</Form.Label>
            <Col>
              <Form.Control as="select" value={selectedConsultant} onChange={handleOnConsultantChange} disabled={isDisabled}>
                <option value=''>--Select Consultant--</option>
                {
                  consultants && Array.isArray(consultants) ? consultants.map(x => {
                    return (
                      <option key={x.name} >{x.name}</option>
                    )
                  }) : <></>
                }
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      }
      <AddOrderToGroupDropDown
        measurements={measurements}
        orderGroupings={orderGroupings}
        handleOrderGroupingsChange={handleOrderGroupingsChange}
      >
      </AddOrderToGroupDropDown>
      {props.children}
      <Row>
        <Col className="text-muted">
          <p className="text-danger">*Double check the R Points used. After saving there is no option to edit R points used.</p>
          <p>*Please make sure deposit amount is collected</p>
        </Col>
      </Row>
      { (!measurements.deleted && !measurements.refunded) && 
        <Row>
          <Col>
            <Button variant="primary" block onClick={handleOnSaveOrderClick}><span className="oi oi-cloud-upload">&nbsp;</span>Save Order</Button>
          </Col>
          <Col>
            <Button variant="primary" block onClick={handleOnDownloadAllOrdersClick} disabled={allDesignFormReady() ? false : true}><span className="oi oi-data-transfer-download">&nbsp;</span>Download All Design Form</Button>
            {!allDesignFormReady() && (
              <span className='text-danger'><small>*Please save the order in order to download</small></span>
            )}
          </Col>
          <Col>
            <Button variant="primary" block onClick={handleOnPrintReceiptClick} disabled={!measurements.id}><span className="oi oi-copywriting">&nbsp;</span>Print Receipt</Button>
          </Col>
          <Col>
            <Button variant="primary" block onClick={handleOnSendReceiptClick} disabled={!measurements.id}><span className="oi oi-envelope-closed">&nbsp;</span>Send Receipt</Button>
          </Col>
          {
            measurements.id && 
            <Col>
              <Button variant="primary" block onClick={handleOnCompleteOrderClick} disabled={measurements.fullyPaid || !allItemTypesAreOthers() || hasItemTypeChanged}><span className="oi oi-check">&nbsp;</span>Complete Order</Button>
            </Col>
          }
        </Row>
      }
      {/* {(<div><pre>{JSON.stringify(salesItems, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(hasItemTypeChanged, null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(allItemTypesAreOthers(), null, 2) }</pre></div>)} */}
      {/* {(<div><pre>{JSON.stringify(measurements, null, 2) }</pre></div>)} */}
    </>
  )
}

export default SalesSummary