import SPFlow from './ProcessFlow/SPFlow';
import JVFlow from './ProcessFlow/JVFlow';
import XFFlow from './ProcessFlow/XFitFlow';
import AlterFlow from './ProcessFlow/AlterFlow';
import FGFlow from './ProcessFlow/FGFlow';
import BasteFitFlow from './ProcessFlow/BFFlow';

const statusHelper = (status, productionType, item_type, fit_gtd=0, setType='A', alteration = false) => {
  let nextOptions = [];
  if( fit_gtd || status === 'ProcessingFG') {
    nextOptions = FGFlow[status] && FGFlow[status]['options'] ? FGFlow[status]['options'] : '';
  } else if ( productionType === 'xfit' || status === 'ProcessingXF') {
    nextOptions =  XFFlow[setType] && XFFlow[setType][status] && XFFlow[setType][status]['options'] ? XFFlow[setType][status]['options'] : '';
  } else if ( productionType === 'alteration' || status === 'ProcessingAT') {
    nextOptions = AlterFlow[status] && AlterFlow[status]['options'] ? AlterFlow[status]['options'] : '';
  } else if ( productionType === 'bastefit' || status === 'ProcessingBF') {
    nextOptions = BasteFitFlow[status] && BasteFitFlow[status]['options'] ? BasteFitFlow[status]['options'] : '';
  } else {
    //production type normal
    if (!status) {
      nextOptions = {
        normal: 'Normal',
        xfit: 'XFit',
        alteration: 'Alteration',
        bastefit: 'BasteFit',
      }
      if(item_type === 'LS' || item_type === 'SS' || item_type === 'PRIMO LS' || item_type === 'PRIMO SS') {
        delete nextOptions.bastefit;
      }
      if (alteration) {
        delete nextOptions.normal;
        delete nextOptions.xfit;
        delete nextOptions.bastefit;
      }
    }
    if (status) {
      switch (item_type) {
        case 'LS':
        case 'PRIMO LS':
        case 'SS':
        case 'PRIMO SS':
        case 'LP':
        case 'PRIMO LP':
        case 'SP':
        case 'PRIMO SP':
          nextOptions = SPFlow[setType] && SPFlow[setType][status] && SPFlow[setType][status]['options'] ? SPFlow[setType][status]['options'] : '';
          break;
        case 'SBJ':
        case 'PRIMO SBJ':
        case 'DBJ':
        case 'PRIMO DBJ':
        case 'SBV':
        case 'PRIMO SBV':
        case 'DBV':
        case 'PRIMO DBV':
          nextOptions = JVFlow[setType] && JVFlow[setType][status] && JVFlow[setType][status]['options'] ? JVFlow[setType][status]['options'] : '';
          break;
        case 'ALTER':
          nextOptions = AlterFlow[status] && AlterFlow[status]['options'] ? AlterFlow[status]['options'] : '';
          break;
        default:
          nextOptions = SPFlow[setType] && SPFlow[setType]['ALTER'] && SPFlow[setType]['ALTER']['options'] ? SPFlow[setType]['ALTER']['options'] : '';
          break;
      }
    }
  }
  
  
  let optionsArray = [];
  if (nextOptions && Object.keys(nextOptions) && Object.keys(nextOptions).length > 0) {
    for (let i=0; i < Object.keys(nextOptions).length; i++) {
      optionsArray.push({
        value: Object.keys(nextOptions)[i],
        label: nextOptions[Object.keys(nextOptions)[i]]
      })
    }
  }
  return optionsArray
}

const isProcessStarted = (item) => {
  if (item.status && item.status !== null && item.status.substring(0,13).toLowerCase() !== 'fabricordered' && item.status.substring(0,14).toLowerCase() !== 'fabricreceived' ) {
    return true;
  }
  return false;
}

export  { statusHelper, isProcessStarted }